import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const LoginSuccess = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>Login Successful!</h1>
      <p>You can now return to the app.</p>
    </Container>
  );
};

export default LoginSuccess;

const headerTitle = 'Game On, Anywhere!';
const headerSubtitle = (
  <>
    I'm a gamer, just like you, ma man. <br />
    I created this app to help you manage your game settings, back up, share, and much more. <br />
    Oh, and I forgot, it's free. IT'S FREEEEE!
  </>
);
const headerSupport = 'Support Windows OS only';
const headerSupportOfficial = 'Compatible with Windows 10 and later.';

const headerSubtileOfficial = `Take your personalized game settings wherever you play. Join our community to use setups from friends, content creators, or share your own.
      Tweak settings without fear, switch profiles with a click. Synk.GG: Carry Your Game.
    `;

const qAndA = [
  {
    question: 'What is synk.gg?',
    answer:
      'Synk.gg is a cool spot to manage your in-game settings and help you ace your gaming stuff. You can backup and restore your settings to our secure cloud, share them with friends, or even use settings from your favorite gamers.',
  },
  {
    question: 'How can I share my game settings?',
    answer:
      "Easy peasy! Hit the share button, name your settings, and you'll get a link. Share that anywhere, and others can grab your settings with just one click using the synk.gg app.",
  },
  {
    question: 'Can I have multiple profiles?',
    answer:
      'Yep, you can set up multiple profiles. Perfect for when you have friends or family using the same PC, so everyone can play with their own preferred settings.',
  },
  {
    question: 'What are the benefits of using synk.gg?',
    answer:
      "You can snag the best performance settings for your hardware or copy killer keybinds and controller setups from top players. It's all about boosting your game without messing up your current setup.",
  },
  {
    question: 'How do I request game support?',
    answer: "Got a game you want to add? Just hit up the app, search for your game, and send us a request. We'll hustle to get it supported.",
  },
  {
    question: 'Can I use this on my mobile or Mac?',
    answer: "Nope, synk.gg is strictly for Windows PCs. It's not made for mobile or Mac, and that’s not changing.",
  },
  {
    question: 'Is this for cheating?',
    answer:
      "Absolutely not! We're all about fair play. Synk.gg only handles settings you can change in the game's own menu. Cheating's a no-go, and we're committed to keeping it clean.",
  },
  {
    question: 'How can I give feedback or get help?',
    answer:
      'Jump onto our Discord server to chat, ask questions, request new features, report bugs, or just hang out. You can also email me directly if you prefer a more private chat.',
  },
];

export { headerTitle, headerSubtitle, headerSupport, headerSupportOfficial, headerSubtileOfficial, qAndA };

import styled from 'styled-components';

export const size = {
  mobile: '768px',
  tablet: '1024px',
  desktop: '1200px',
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  desktop: `(max-width: ${size.desktop})`,
};

export const colors = {
  primary: '#b62313c5',
  secondary: '#454552',
  blackBackground: '#333',
  whiteTextHige: 'rgba(255, 255, 255, 0.87)',
  whiteTextLow: 'rgba(255, 255, 255, 0.6)',
};

export const featureList = [
  {
    title: 'Portability',
    content: `Game anywhere with your custom settings.<br /> No manual adjustments, no hassle. Experience boundary-less gaming.`,
  },
  {
    title: 'Community Sharing',
    content: `Explore settings from friends, creators, or the community.<br /> Share your own. Enhance gaming through shared experiences.`,
  },
  {
    title: 'Fearless Tweaking',
    content: 'Modify your game settings without worries. Our app remembers your originals. Be bold, try new strategies.',
  },
  {
    title: 'User Switching',
    content: 'Multiple Users, One PC: Switch between game profiles in a snap.<br /> Perfect for households, roommates, or gaming cafes.',
  },
];

export const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  color: ${colors.whiteTextHige};
  min-height: calc(100vh - 75px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  min-width: 1200px;
  width: 60%;
  flex-grow: 1;
  background-color: ${colors.secondary};
  padding: 4rem 4rem 2rem;
  box-sizing: border-box;
  overflow: visible;
  border-radius: 10px;

  @media ${device.mobile} {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    min-width: 0;
  }

  @media ${device.tablet} {
    min-width: 0;
    flex-direction: column;
  }
`;

export const Header = styled.h1`
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;

  @media ${device.mobile} {
    font-size: 2rem;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 2rem;

  @media ${device.mobile} {
    margin-top: 0rem;
    margin-left: 0;
    align-items: center;
    padding: 0;
  }
`;

export const Subheading = styled.h2`
  font-weight: normal;
  font-size: 1.5rem;
  width: 90%;
  line-height: 2rem;

  @media ${device.mobile} {
    text-align: center;
    width: 100%;
  }
`;

export const DownloadButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.whiteTextHige};
  border: none;
  padding: 15px 15px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.25s;
  align-self: start;

  &:hover {
    transform: scale(0.98);
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-self: center;
  margin-top: 8rem;
  width: 100%;
  @media (max-width: 1200px) {
    flex-basis: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: auto;
  }
`;

export const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  box-sizing: border-box;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  height: 600px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  width: 350px;
`;

export const SuppurtOS = styled.p`
  font-size: 1.2rem;
  color: ${colors.whiteTextLow};
  text-decoration: none;
`;

import React from 'react';
import FeatureCard from '../FeatureCard';
import { featureList, FeaturesContainer } from './styles';

const FeatureSection = () => (
  <FeaturesContainer>
    {featureList.map((feature, index) => (
      <FeatureCard key={index} title={feature.title}>
        {feature.content}
      </FeatureCard>
    ))}
  </FeaturesContainer>
);

export default FeatureSection;

import styled from 'styled-components';
import React from 'react';
import SettingsDetails from './SettingsDetails';
import Discord from './Discord';

const SharePage = () => {
  return (
    <Container>
      <SettingsDetails />
      <Discord />
    </Container>
  );
};

export default SharePage;

export const Container = styled.div`
  background-color: #3c3c47;
  color: #ffffff;
  width: 100vw;
  min-height: calc(100vh - 70px);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

export const discordServerUrl = 'https://discord.gg/4bDaMW3B6t';

export function RedirectToDiscord() {
  useEffect(() => {
    ReactGA.event({
      category: 'Redirect',
      action: 'Redirect to Discord',
      label: 'Discord',
    });
    window.location.href = discordServerUrl;
  }, []);

  return <p>Redirecting to Discord...</p>;
}

import React, { useState } from 'react';
import axios from 'axios';

function ImportGameSettings() {
  const [file, setFile] = useState(null);
  const [gameId, setGameId] = useState('');
  const [message, setMessage] = useState('');
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleGameIdChange = (e) => {
    setGameId(e.target.value);
  };

  const handleUpload = async () => {
    if (!file || !gameId) {
      setMessage('Please select a file and enter a game ID.');
      return;
    }

    const formData = new FormData();
    formData.append('csv_file', file);

    try {
      const response = await axios.post(`${baseServerUrl}/update_game/${gameId}`, formData);
      if (response.status !== 200) {
        setMessage('An error occurred while importing settings.');
        return;
      } else {
        setMessage('Settings imported successfully!');
      }
      // You may want to handle the response as needed
    } catch (error) {
      setMessage('An error occurred while importing settings.');
      // You may want to log the error or handle it as needed
    }
  };

  return (
    <div>
      <h1>Import Game Settings</h1>
      <label>Game ID: </label>
      <input type="text" value={gameId} onChange={handleGameIdChange} />
      <br />
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload}>Import</button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ImportGameSettings;

import React from 'react';
import MainPage from './mainPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SharePage from './sharePage';
import Toolbar from './Toolbar';
import { createGlobalStyle } from 'styled-components';
import ImportGameSettings from './UploadPage';
import { RedirectToDiscord } from './Redirects';
import DownloadPage from './DownloadPage';
import LoginSuccess from './pages/LoginSuccess';
import FaqSection from './components/FaqSection';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif !important;
  }
`;

ReactGA.initialize('G-L8T3X51N8P');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Toolbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/discord" element={<RedirectToDiscord />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/loginSuccess" element={<LoginSuccess />} />
        <Route path="/share/:id" element={<SharePage />} />
        <Route path="/faq" element={<FaqSection />} />
        <Route path="/upload" element={<ImportGameSettings />} />
      </Routes>
    </Router>
  );
}
export default App;

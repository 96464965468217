import React from 'react';
import styled from 'styled-components';
import { DownloadButton, Header, Subheading, SuppurtOS, device } from './styles';
import ReactGA from 'react-ga4';
import { headerTitle, headerSubtitle, headerSupport } from '../Texts';
import { Link } from 'react-router-dom';

const handleDownloadClick = () => {
  ReactGA.event({
    category: 'Button',
    action: 'Download',
    label: 'Download Button clicked',
  });
};

const HeaderSection = () => (
  <TextContainer>
    <Header>{headerTitle}</Header>
    <Subheading>{headerSubtitle}</Subheading>
    <ButtonsContainer>
      <Link to="/download" style={{ marginRight: '24px' }} onClick={handleDownloadClick}>
        <DownloadButton>FREE DOWNLOAD</DownloadButton>
      </Link>
      <Link to="/faq">
        <LearnMoreButton>Learn More</LearnMoreButton>
      </Link>
    </ButtonsContainer>
    <SuppurtOS>{headerSupport}</SuppurtOS>
  </TextContainer>
);

export default HeaderSection;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 2rem;

  @media ${device.mobile} {
    margin-top: 0rem;
    margin-left: 0;
    align-items: center;
    padding: 0;
  }
`;

const LearnMoreButton = styled(DownloadButton)`
  background-color: #424242;
  border: 2px solid #b1b1b1;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px 25px;
  font-weight: normal;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
    gap: 16px;
  }
`;

import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Container } from './styles';
import styled from 'styled-components';
import { qAndA } from '../Texts';

const AccordionStyled = styled(Accordion)`
  width: 80%;
  margin-top: 100px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 24px;
    width: 100%;
  }
`;

const AccordionItemStyled = styled(AccordionItem)`
  background-color: #333;
  color: #ffffff;
  margin: 20px;
  border-radius: 10px; // Rounded corners only on top
`;

const AccordionItemButtonStyled = styled(AccordionItemButton)`
  background-color: #4545528f;
  color: #ffffff;
  border-radius: 10px; // Rounded corners only on top
  padding: 24px;
  font-size: 1.3rem;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: background-color 0.3s ease; // Subtle hover animation

  &:hover {
    background-color: #565662; // Darkening on hover
  }

  &::after {
    content: '▼'; // Unicode for down-pointing triangle
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
  }

  &[aria-expanded='true']::after {
    transform: translateY(-50%) rotate(180deg); // Flip the arrow when expanded
  }
`;

const AccordionItemPanelStyled = styled(AccordionItemPanel)`
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); // Softer shadow for answers
  border-radius: 0 0 10px 10px; // Rounded corners only on the bottom
  font-size: 1.2rem;
  overflow: hidden;
`;

const FaqSection = () => {
  return (
    <Container>
      <AccordionStyled allowZeroExpanded="true">
        {qAndA.map((qa, index) => (
          <AccordionItemStyled key={index}>
            <AccordionItemHeading>
              <AccordionItemButtonStyled>{qa.question}</AccordionItemButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanelStyled>
              <p>{qa.answer}</p>
            </AccordionItemPanelStyled>
          </AccordionItemStyled>
        ))}
      </AccordionStyled>
    </Container>
  );
};

export default FaqSection;

import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DarkDialog } from './DarkDialog';
import Cookies from 'js-cookie';
import axios from 'axios';

const baseServerUrl = process.env.REACT_APP_SERVER_URL;

const getSettings = async (game_id, settings_name) => {
  const serverUrl = `${baseServerUrl}/settings/others`;
  const response = await axios.get(serverUrl, {
    params: {
      game_id: game_id,
      settings_name: settings_name,
    },
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error('Failed to load settings');
  }
};

const getGame = async (gameUrlName) => {
  const serverUrl = `${baseServerUrl}/game/${gameUrlName}`;
  const response = await axios.get(serverUrl);

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error('Failed to load game');
  }
};

const convertDate = (date) => {
  const creationDate = new Date(date);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return creationDate.toLocaleString('en-US', options);
};

const SettingsDetails = () => {
  let { id } = useParams();
  const [settings, setSettings] = useState({});
  const [game, setGame] = useState({});
  const [videoUrl, setVideoUrl] = useState('');
  const [creationDate, setCreationDate] = useState('');

  function decodeSlug(inputString) {
    return inputString.replace(/-/g, ' ');
  }

  useEffect(() => {
    const downloadGameAndSettings = async () => {
      const [gameUrlName, settingsNameUrl] = id.split('@');
      const settingsName = decodeSlug(settingsNameUrl);
      const game = await getGame(gameUrlName);
      const settings = await getSettings(game.game_id, settingsName);
      setGame(game);
      setSettings(settings);
      setVideoUrl(getEmbedYoutubeUrl(game.video_url, settings.video_url));
      setCreationDate(convertDate(settings.creation_date));
    };

    downloadGameAndSettings();
  }, [id]);

  const onImportButtonClicked = useCallback(() => {
    const rememberedChoice = Cookies.get('rememberedChoice');

    if (rememberedChoice === 'import') {
      const appLink = 'synk-gg://' + id;
      window.location.replace(appLink);
      return;
    }

    DarkDialog.fire({
      title: 'Import Game Settings!',
      html: 'To import the settings, the Synk app must be installed on your PC. If you have the app, you can import the settings immediately.<br><br><div style="text-align:left;"><label><input type="checkbox" id="rememberChoiceCheckbox" /> Remember my choice</label></div>',
      showDenyButton: true,
      confirmButtonText: 'Import Settings',
      denyButtonText: 'Download App',
      preConfirm: () => {
        const checkbox = document.getElementById('rememberChoiceCheckbox');
        return { rememberChoice: checkbox.checked };
      },
    }).then((result) => {
      if (result.value && result.value.rememberChoice && result.isConfirmed) {
        Cookies.set('rememberedChoice', 'import');
      }
      if (result.isConfirmed) {
        const appLink = 'synk-gg://' + id;
        window.location.replace(appLink);
      } else if (result.isDenied) {
        ReactGA.event({
          category: 'pageview',
          action: 'Use shared link',
          label: 'download app',
        });
        window.location.href = '/';
      }
    });
  }, [id]);

  const getEmbedYoutubeUrl = (defaultUrl, settingsUrl) => {
    if (!defaultUrl && !settingsUrl) return;
    const url = settingsUrl || defaultUrl;
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <ChartContainer>
      <ChartHeader>
        <HeaderLogo src={game.icon_url} />
        <HeaderTexts>
          <HeaderGameName>{game.game_name}</HeaderGameName>
          <HeaderGameGenre>{game.genre}</HeaderGameGenre>
        </HeaderTexts>
      </ChartHeader>
      <DownloadsContainer>
        <ChartTitle>{settings.settings_name}</ChartTitle>
      </DownloadsContainer>
      <ShareDate>{`Shared at: ${creationDate}`}</ShareDate>
      <VideoFrame
        src={videoUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      <Table>
        <TableRow>
          <p style={{ color: '#ffffffcd' }}>Shared by</p>
          <p style={{ color: '#ffffff' }}>{settings.user_name}</p>
        </TableRow>
        <Devider />
        <TableRow>
          <p style={{ color: '#ffffffcd' }}>Downloads</p>
          <p style={{ color: '#ffffff' }}>{settings.downloads}</p>
        </TableRow>
        <Devider />
        <TableRow>
          <p style={{ color: '#ffffffcd', alignSelf: 'start' }}>Description</p>
          <p style={{ color: '#ffffff', width: '50%', textAlign: 'end' }}>{settings.description}</p>
        </TableRow>
      </Table>
      <Button onClick={onImportButtonClicked}>Import Settings</Button>
    </ChartContainer>
  );
};

export default SettingsDetails;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  margin-top: 70px;
  background-color: #333;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`;

const ChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
`;

const HeaderLogo = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;

const HeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 50px;
  margin-left: 10px;
`;

const HeaderGameName = styled.p`
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0;
`;

const HeaderGameGenre = styled.p`
  color: #a6a5a5;
  font-size: 1rem;
  margin: 0;
`;

const DownloadsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
`;

const ChartTitle = styled.p`
  color: #ffffff;
  font-size: 2.3rem;
  margin: 0;
  overflow: hidden; // Hide the overflowing text
  text-overflow: ellipsis; // Add an ellipsis if the text overflows
  white-space: nowrap; // Prevent the text from wrapping to the next line
`;

const ShareDate = styled.p`
  color: #a6a5a5;
  font-size: 1rem;
  align-self: start;
  margin-top: 15px;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 225px;
  border: none;
  box-shadow: 0px 0px 2px 3px #000000;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: 50px;
  width: 100%;
`;

const Devider = styled.div`
  background-color: #ffffff88;
  height: 1px;
  width: 100%;
`;

const Button = styled.button`
  background-color: #b62313c5;
  color: #ffffff;
  border: none;
  padding: 10px 40px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: transform 0.25s;
  &:hover {
    cursor: pointer;
    transform: scale(0.98);
  }
`;

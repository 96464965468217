import React from 'react';
import CookieConsent from 'react-cookie-consent';

const buttonStyle = {
  background: '#fff',
  // add border radius
  borderRadius: '100px',
};

const contentStyle = {
  display: 'flex',
  alignContent: 'center',
};

const MyCookieConsent = () => {
  return (
    <CookieConsent buttonStyle={buttonStyle} contentStyle={contentStyle}>
      We use cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default MyCookieConsent;

import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';

// Require all images in the screenshots directory
const importAll = (r) => r.keys().map(r).sort();
const images = importAll(require.context('../assets/screenshots', false, /\.png$/));

export default function ScreenshotsSlider() {
  const isMobile = window.innerWidth <= 768;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: isMobile ? 'ondemand' : 'progressive',
  };

  return (
    <TextContainer>
      <SliderStyle>
        <Slider {...settings}>
          {images.map((image, index) => (
            <ScreenshotWrapper key={index}>
              <Image src={image} alt={`Screenshot of Synk.gg app interface ${index + 1}`} />
            </ScreenshotWrapper>
          ))}
        </Slider>
      </SliderStyle>
    </TextContainer>
  );
}

const ScreenshotWrapper = styled.div``;

const Image = styled.img`
  height: 550px;
  width: 320px;
  object-fit: contain;
`;

const SliderStyle = styled.div`
  height: 550px;
  width: 320px;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 2rem;
`;

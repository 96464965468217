import React from 'react';
import HeaderSection from './components/HeaderSection';
import FeatureSection from './components/FeatureSection';
import ScreenshotsSlider from './components/screenshotsSlider';
import Discord from './Discord';
import { Container, SideBySide, BottomBar } from './components/styles';
import MyCookieConsent from './components/MyCookieConsent';

const MainPage = () => (
  <Container>
    <SideBySide>
      <HeaderSection />
      <ScreenshotsSlider />
    </SideBySide>
    <FeatureSection />
    <BottomBar>
      <Discord />
      <MyCookieConsent />
    </BottomBar>
  </Container>
);

export default MainPage;

import styled from 'styled-components';
import { discordServerUrl } from './Redirects';
import discordLogo from './assets/discord-logo-white.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* padding-top: 16px; */
  /* padding-bottom: 24px; */
`;

const DiscordTitle = styled.p`
  margin-right: 16px;
  color: #fff;
  font-size: 25px;
`;

const Discord = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <Container href={discordServerUrl}>
      {!isMobile && <DiscordTitle>Ask me anything at</DiscordTitle>}
      <a href={discordServerUrl} target="_blank" rel="noreferrer noopener">
        <img style={{ width: '150px' }} src={discordLogo} alt="Talk to me on" />
      </a>
    </Container>
  );
};

export default Discord;

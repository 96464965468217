import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const ToolbarContainer = styled.div`
  top: 0;
  width: 60%;
  min-width: 1200px;
  height: 60px;
  background-color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: auto;

  /* Add mobile style */
  @media (max-width: 768px) {
    width: 100%;
    min-width: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
`;

const Title = styled.h1`
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  margin-left: 12px;
`;

const Icon = styled.img`
  height: 2rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 24px;

  /* Add hover effect */
  a:hover {
    color: #f1f1f1;
  }
`;

const Toolbar = () => {
  const { pathname } = useLocation();
  return (
    <ToolbarContainer>
      <StyledLink to="/">
        <LogoContainer>
          <Icon src="/icon.png" alt="icon" />
          <Title>SYNK.GG</Title>
        </LogoContainer>
      </StyledLink>
      <RightContainer>
        {/* {!isMobile && <StyledLink to="/download">DOWNLOAD</StyledLink>} */}
        {pathname !== '/faq' && <StyledLink to="/faq">WHAT IS SYNK.GG</StyledLink>}
        {/* <StyledLink to="/discord">DISCORD</StyledLink> */}
      </RightContainer>
    </ToolbarContainer>
  );
};

export default Toolbar;

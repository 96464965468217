import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';

// Styled-components
const PageContainer = styled.div`
  height: 60vh;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Subheader = styled.p`
  font-size: 1rem;
  color: #a9a9b3;
  margin-bottom: 2rem;
`;

const DownloadButton = styled.button`
  background-color: #b62313c5;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.25s;

  &:hover {
    transform: scale(0.98);
  }
`;

const SupportText = styled.p`
  color: #ffffff;
  margin-top: 2rem;

  a {
    color: #4a90e2; /* Sets the link color to a lighter blue */
    text-decoration: none; /* Removes underline from links */
    transition: color 0.3s; /* Smooth color transition for hover effect */

    &:hover {
      color: #d62d20; /* Changes color to red when hovered */
      text-decoration: underline; /* Adds underline on hover for better visibility */
    }

    &:active {
      color: #0056b3; /* Darker blue for when the link is clicked */
    }
  }
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.8rem;
  background: #15151e;
  color: #a9a9b3;
  text-align: center;
`;

const DownloadPage = () => {
  React.useEffect(() => {
    handleDownload(true);
  }, []);
  const handleDownload = (automaticly = false) => {
    ReactGA.event({
      category: 'Download',
      action: 'Download initiated',
      label: 'SYNK.GG',
    });
    console.log('Download initiated, automaticly:', automaticly);

    window.location.href = 'https://storage.googleapis.com/electron_app/synk.gg.exe';
  };

  return (
    <PageContainer>
      <Header>Ready to Level Up Your Game?</Header>
      <Subheader>Your download will start shortly. If it doesn't, click the button below.</Subheader>
      <DownloadButton onClick={handleDownload}>Download SYNK.GG</DownloadButton>
      <SupportText>
        Need help? Visit our <a href="https://discord.gg/4bDaMW3B6t">Discord server</a> or <a href="mailto:support@synk.gg">contact me</a>.
      </SupportText>
      <Footer>© {new Date().getFullYear()} SYNK.GG. All rights reserved.</Footer>
    </PageContainer>
  );
};

export default DownloadPage;

import React from 'react';
import styled from 'styled-components';

// Define reusable breakpoint sizes for easier maintenance
const Feature = styled.p`
  font-size: 1.1rem;
  color: #ffffffb9;
  margin: 0.5rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #4545528f;
  line-height: 1.5rem;
  text-align: center;
  display: flex;
  height: 140px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  /* box-shadow: 0px 8px 16px rgba(182, 35, 19, 0.77); */
`;

const FeatureCardStyle = styled.div`
  flex: 1;
  border-radius: 10px;
  background-color: #333;
  margin: 2rem;
  aspect-ratio: 1;
  width: 250px;
  height: 250px;
  max-width: 250px;
  min-width: 250px;
  max-height: 250px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
`;

const FeatureCard = ({ children, title }) => {
  return (
    <FeatureCardStyle>
      <h3 style={{ textAlign: 'center' }}>{title}</h3>
      <Feature dangerouslySetInnerHTML={{ __html: children }}></Feature>
    </FeatureCardStyle>
  );
};

export default FeatureCard;
